import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";

function Header() {
    const mobile = window.innerWidth <= 768 ? true : false;
    const [menuOpened, setMEnuOpened] = useState(false);
    return (
        <div className="header">
            <img src={Logo} alt="logo" className="logo" />
            {menuOpened === false && mobile === true ? (
                <div
                    style={{
                        backgroundColor: "var(--appColor)",
                        padding: "0.5rem",
                        borderRadius: "5px",
                    }}
                    onClick={() => setMEnuOpened(true)}
                >
                    <img
                        src={Bars}
                        style={{ width: "1.5rem", height: "1.5rem" }}
                    />
                </div>
            ) : (
                <ul className="header-menu">
                    <li>
                        <Link
                            to="home"
                            span={true}
                            smooth={true}
                            onClick={() => setMEnuOpened(false)}
                            activeClass="active"
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="Programs"
                            span={true}
                            smooth={true}
                            onClick={() => setMEnuOpened(false)}
                        >
                            Programs
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="reasons"
                            span={true}
                            smooth={true}
                            onClick={() => setMEnuOpened(false)}
                        >
                            Why us
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="plans"
                            span={true}
                            smooth={true}
                            onClick={() => setMEnuOpened(false)}
                        >
                            Plans
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="testimonials"
                            span={true}
                            smooth={true}
                            onClick={() => setMEnuOpened(false)}
                        >
                            Testimonials
                        </Link>
                    </li>
                </ul>
            )}
        </div>
    );
}

export default Header;
